#twitchFrame {
  position: relative;
  left: 10px;
  height: 418px;
  width: 660px;
  z-index: 2;
}

.dolly {
  height: 50px;
  position: relative;
  left: 25px;
  -webkit-box-shadow: 10px 10px 20px -3px rgba(242, 145, 242, 1);
  animation: pulse;
}

.loginLink {
  position: relative;
  left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  height: 40px;
  width: 90px;
  color: #9146f2;
  font-size: 20px;
  animation: pulse;
  background-color: transparent;
}

.loginLink:hover {
  -webkit-box-shadow: 5px 5px 33px 5px #9146f2;
}

.loginlinkA {
  text-decoration: none;
}
