.sideBanner {
  position: fixed;
  right: 10px;
  top: 5%;
  min-height: 600px;
  max-height: 96%;
  width: 175px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sideBannerImage {
  position: fixed;
  top: 2%;
  right: 10px;
  width: 170px;
  max-height: 96%;
  z-index: -2;
}

.sideBannerInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 450px;
  z-index: 10;
  top: 20px;
  width: 150px;
  word-wrap: break-word;
  
}

.sideBannerTitle,
.sideBannerDate,
.sideBannerLocation {
  background-color: rgba(65, 255, 198, 0.89);
  padding: 3px;
  margin: 3px;
  color: black;
}

.sideBannerEventImage {
  width: 140px;
  height: 140px;
  align-self: center;
  margin: 10px 0px;
}

a {
  text-decoration: none;
}
