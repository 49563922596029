.navBar__eventBoard__background {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(173, 216, 230, 0.8);
}

.events__title {
  color: #9146f2;
  font-size: 30px;
  position: fixed;
  top: 15px;
  left: 10%;
  font-style: bold;
}
