html {
  margin: 0px;
}

body {
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0px;
  font-family: monospace;
  text-decoration: none;
}

a {
  color: inherit;
}

.appBoard {
  position: relative;
  padding: 0px 20px;
  top: 18vh;
  width: 40%;
}

.background {
  position: relative;
  background-image: url("./images/cloud4.jpg");
  background-repeat: round;
  height: 1334px;
  width: 2000px;
  left: 0px;
  top: 0px;
  /* z-index: -300; */
}

iframe {
  clear: both;
  border: 8px;
  border-style: solid;
  border-image-slice: 6;
  border-image-source: linear-gradient(
    90deg,
    transparent,
    rgba(9, 14, 207, 0.6068627280013568) 3%,
    rgba(69, 230, 21, 0.6796918596540178) 91%
  );
}

.logo-image {
  position: fixed;
  left: 0px;
  top: 0px;
  height: 18vh;
}

.contentArea {
  position: relative;
  top: 120px;
}

.navBar {
  position: fixed;
  display: flex;
  width: 100%;
  flex-direction: row;
  bottom: 0px;
  z-index: 5;
}

.navLink,
.eventNavLink {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9146f2;
  font-size: 20px;
  text-decoration: none;
  width: 150px;
  height: 85px;
}

.eventNavLink {
  font-size: 20px !important;
  border: none;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  outline: none;
}

.navLink:hover,
.eventNavLink:hover {
  background-image: linear-gradient(
    90deg,
    rgba(63, 108, 15, 0) 4%,
    rgba(95, 236, 40, 1) 29%,
    rgba(21, 48, 230, 1) 84%
  );
}

.navLink--active {
  background-image: radial-gradient(
    circle,
    rgba(95, 236, 40, 1) 21%,
    rgba(9, 14, 207, 0.9850139885055584) 28%,
    rgba(21, 48, 230, 0) 50%
  );
}

.eventNavLink:focus {
  background-image: radial-gradient(
    circle,
    rgba(95, 236, 40, 1) 21%,
    rgba(9, 14, 207, 0.9850139885055584) 28%,
    rgba(21, 48, 230, 0) 50%
  );
}

.aboutText {
  padding: 0px 25px;
  font-size: 15px;
}

.aboutInfoImage {
  position: relative;
  min-width: 950px;
  width: 100%;
}

.externalLinks {
  text-decoration: none;
  font-weight: bold;
  color: #9146f2;
}

.bold {
  font-weight: bold;
}

.sideBannerModal {
  max-width: 300px;
  max-height: 400px;
}

.sideBanner:hover {
  opacity: 0.7;
}

.eventArchiveBoard {
  position: relative;
  top: -10px;
  max-height: 450px;
  min-width: 800px;
  width: 80%;
  color: white;
  clear: both;
  border: 8px;
  border-style: solid;
  border-image-slice: 6;
  border-image-source: linear-gradient(
    90deg,
    transparent,
    rgba(9, 14, 207, 0.6068627280013568) 3%,
    rgba(69, 230, 21, 0.6796918596540178) 91%
  );
  opacity: 0.9;
  overflow-y: scroll;
}

.eventBoard {
  position: fixed;
  max-height: 525px;
  min-width: 600px;
  max-width: 800px;
  width: 80%;
  color: white;
  position: fixed;
  left: 10%;
  top: 10vh;
  clear: both;
  border: 8px;
  border-style: solid;
  border-image-slice: 6;
  border-image-source: linear-gradient(
    90deg,
    transparent,
    rgba(9, 14, 207, 0.6068627280013568) 3%,
    rgba(69, 230, 21, 0.6796918596540178) 91%
  );
  overflow-y: scroll;
  animation: 0.5s ease-out 0s 1 slideInFromLeft;
}

.eventSingleCard {
  background-color: black;
  border-bottom: 1px solid #9146f2;
  display: flex;
  flex-direction: column;
}
.eventSingleCard:hover {
  background-color: rgb(56, 56, 56);
}

.linkList {
  list-style: none;
  padding: 3px;
  color: lime;
}



.eventTitle {
  border-right: 1px solid #9146f2;
  font-size: 15px;
  text-decoration: none;
  color: white;
  display: flex;
  flex-direction: row;
}
.eventDate {
  border-left: 1px solid #9146f2;
  display: flex;
  flex-direction: column;
  min-width: 220px;
}

.eventLocation,
.eventDate,
.eventTitle,
.eventDescription,
.eventLink {
  padding: 3px 6px;
}

.topRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  cursor: pointer;
}

.eventDescription {
  overflow-y: scroll;
}
.eventImage {
  height: 150px;
  object-fit: cover;
}

.imageDescription {
  display: flex;
  flex-direction: row;
  max-height:200px;
  border-top: 1px solid #9146f2;
  border-bottom: 1px solid #9146f2;
}

.deleteEventModal {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 400px;
  height: 500px;
  background-color: wheat;
  top: 100px;
  left: 200px;
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  color: white;
}

.login {
  padding: 15px;
  width: 300px;
  height: 200px;
  clear: both;
  background-color: lime;
  border: 10px;
  border-style: solid;
  border-image-slice: 6;
  border-width: 8px;
  border-image-source: linear-gradient(
    90deg,
    transparent,
    rgba(9, 14, 207, 0.6068627280013568) 3%,
    rgba(69, 230, 21, 0.6796918596540178) 91%
  );
}

.loginButton {
  background-color: lightblue;
  cursor: pointer;
  margin: 5px;
}
.adminButton {
  position: fixed;
  top: 5px;
  right: 10px;
}

.deleteEventButton {
  width: 25px;
  background-color: pink;
}

.bannerModal {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 400px;
  height: 500px;
  background-color: lime;
  top: 100px;
  left: 200px;
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  color: black;
}

.newUserForm {
  display: flex;
  flex-direction: column;
  width: 50%;
}

input {
  margin: 5px 0px;
}

.eventModal {
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 400px;
  height: 500px;
  background-color: lime;
  top: 100px;
  left: 200px;
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  color: black;
  z-index: 20;
}

.eventForm {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 353px;
}
.closeEvents {
  position: absolute;
  right: 7px;
  bottom: 0px;
  font-size: 50px;
  color: lime;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  z-index: 50;
  cursor: pointer;
  font-family: sans-serif;
}
.eventsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 100px;
  background-color: black;
  color: white;
  height: 35px;
  border-bottom: 1px solid #9146f2;
  overflow: visible;
}
.modalButton {
  position: relative;
  bottom: 0px;
  background-color: lime;
  margin-top: 10px;
  height: 30px;
  width: 150px;
  border-radius: 50px;
}
.eventModalX {
  position: absolute;
  top: 5px;
  right: 5px;
}

.newEvent {
  background-color: white;
  width: 90%;
  height: 80%;
  word-wrap: normal;
  overflow: scroll;
  padding: 10px;
}

.errorMessage {
  background-color: yellow;
  font-style: italic;
  font-size: 20px;
}

.loading {
  font-size: 50px;
}

li {
  list-style-type: none;
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(200%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-300%);
  }
  100% {
    transform: translateX(0);
  }
}
